// Here you can add other styles
.button-green {
    background-color: rgb(63, 230, 63);
    border-color: rgb(63, 230, 63);
    color: white;
}

.button-yellow {
    background-color: rgb(255, 255, 67);
    border-color: rgb(255, 255, 67);
    color: black;
}

.button-blue {
    background-color: blue;
    border-color: blue;
    color: white;
}
